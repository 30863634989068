export default defineNuxtRouteMiddleware((to, from) => {
  const { status } = useAuth()
  const nuxtApp = useNuxtApp()

  if (status === 'authenticated') {
    // const defaulPath = app.i18nPath('/')
    // const path = $auth.$storage.getUniversal('redirect') || defaulPath
    // $auth.$storage.setUniversal('redirect', null)

    return navigateTo(nuxtApp.$i18nPath('/'))
  }
})
